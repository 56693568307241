import React from "react";
import styled from "styled-components";
import Section from "../styled/Section";
import Article from "../styled/Article";
import Row from "../styled/Row";
import Column from "../styled/Column";

const NavContainer = styled(Section)`
  background-color: #111111;
  color: #ffffff;
`;

const Footer = () => (
  <footer>
    <NavContainer>
      <Article margin={5}>
        <Row margin={0}>
          <Column width={100}>
            &copy; {new Date().getFullYear()} Molly Isaac
          </Column>
        </Row>
      </Article>
    </NavContainer>
  </footer>
);

export default Footer;
