import styled from "styled-components";

const Column = styled.div`
  width: ${(props) => props.width + "%" || "auto"};
  min-width: ${(props) => props.minwidth || "none"};
  margin: ${props => props.margin || 0}rem 0;

  @media (max-width: 768px) {
    width: 100%;
    margin: ${(props) => (props.noVerticalMargin ? 0 : 2)}rem 0;
  }
`;

export default Column;
