import React from "react";
import styled from "styled-components";
import { sansSerifFont } from "./_mixins";

const Link = styled.a`
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #ffffff;
  font-size: 0.8rem;

  @media (max-width: 768px) {
    padding: 1rem 0;
  }

  &:hover {
    text-shadow: 0px 0px 10px #ffffff;
  }

  ${sansSerifFont}
`;

const NavLink = (props) => (
  <li>
    <Link {...props}>{props.children}</Link>
  </li>
);

export default NavLink;
