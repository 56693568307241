import React from "react";
import styled from "styled-components";
import aboutImg from "../../img/about.jpg";
import H2 from "../styled/H2";
import Section from "../styled/Section";
import Main from "../styled/Main";
import Article from "../styled/Article";
import Row from "../styled/Row";
import Column from "../styled/Column";
import P from "../styled/P";
import Link from "../styled/Link";
import Img from "../styled/Img";

const SectionIntro = styled(Section)`
  color: #ffffff;
  background: #3a3a3a;
`;

const About = () => (
  <SectionIntro id="details">
    <Main>
      <Article margin={6}>
        <H2 underlined light>
          Details
        </H2>
        <Row margin={5}>
          <Column width={45}>
            <P>
              UK based hair and makeup artist for TV and film, specialising in
              prosthetics and makeup FX.
            </P>
            <br />
            <P>
              Experienced in straight makeup, basic hairstyling, makeup FX,
              sculpting, fibre glass, silicone running and prosthetic
              application.
            </P>
            <br />
            <P>
              Get in touch <br />
              <Link href="mailto:irishphantom11@gmail.com">
                irishphantom11@gmail.com
              </Link>
            </P>
          </Column>
          <Column width={40}>
            <Img src={aboutImg} alt="Molly Isaac applying makeup" />
          </Column>
        </Row>
      </Article>
    </Main>
  </SectionIntro>
);

export default About;
