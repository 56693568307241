import React from "react";
import styled from "styled-components";
import logoImg from "../../img/logo.png";
import NavBar from "../styled/NavBar";
import NavLink from "../styled/NavLink";
import Section from "../styled/Section";
import Article from "../styled/Article";
import Row from "../styled/Row";
import Column from "../styled/Column";

const Header = styled.header`
  top: 0;
  left: 0;
  z-index: 10;

  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    top: unset;
    width: 100%;
  }
`;

const NavContainer = styled(Section)`
  background-color: #111111;
`;

const Navigation = () => (
  <Header>
    <NavContainer>
      <Article margin={1}>
        <Row margin={0}>
          <Column width={100} noVerticalMargin>
            <NavBar logo={logoImg}>
              <NavLink href="#home">Home</NavLink>
              <NavLink href="#details">Details</NavLink>
              <NavLink href="#portfolio">Portfolio</NavLink>
              <NavLink
                href="https://www.instagram.com/phantommufx/"
                target="_blank"
                rel="noopener"
              >
                Instagram
              </NavLink>
              <NavLink
                href="https://www.imdb.com/name/nm13342685/"
                target="_blank"
                rel="noopener"
              >
                IMDB
              </NavLink>
            </NavBar>
          </Column>
        </Row>
      </Article>
    </NavContainer>
  </Header>
);

export default Navigation;
