import React, { useState } from "react";
import styled from "styled-components";
import VisibilitySensor from "react-visibility-sensor";

const ImgContainer = styled.div`
  height: ${(props) => props.height}rem;
  background: center center no-repeat #3a3a3a
    ${(props) => (props.viewed ? `url(${props.src})` : "")};
  background-size: cover;
  cursor: pointer;
  position: relative;

  &:hover {
    &:before {
      content: "";
      background: rgba(0, 0, 0, 0.5);
      height: 100%;
      width: 100%;
      position: absolute;
    }
    &:after {
      content: "\u2315";
      color: #ffffff;
      position: absolute;
      font-size: 4rem;
      width: 100%;
      text-align: center;
    }
  }
`;

const PortfolioImg = (props) => {
  const [viewed, setViewed] = useState(false);
  return (
    <VisibilitySensor
      partialVisibility
      onChange={(isVisible) => {
        if (isVisible) setViewed(true);
      }}
    >
      <ImgContainer viewed={viewed} {...props} />
    </VisibilitySensor>
  );
};

export default PortfolioImg;
