import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: ${(props) => props.margin}rem 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 2rem;
  }
`;

export default Row;
