module.exports = {
  entries: [
    {
      image: "/img/cat.jpg",
      title: "Sphinx Cat Makeup",
      description: "5 piece prosthetic makeup",
    },
    {
      image: "/img/xmas.jpg",
      title: "Gruesome Christmas Decorations",
      description: "",
    },
    {
      image: "/img/lizard-ahs.jpg",
      title: "AHS Lizard Girl",
    },
    {
      image: "/img/beaten.jpg",
      title: "Beaten up Makeup",
      description: "5 piece prosthetic makeup",
    },
    {
      image: "/img/bullet.jpg",
      title: "Bullet Hole Makeup",
    },
    {
      image: "/img/cat-clay.jpg",
      title: "Sphinx Cat Sculpt",
    },
    {
      image: "/img/demon.jpg",
      title: "Zodiac Demon Body Paint",
      description: "Body paint and prosthetic horns",
    },
    {
      image: "/img/jigsaw.jpg",
      title: "Saw Puzzle Piece",
    },
    {
      image: "/img/large-lac.jpg",
      title: "Large Laceration",
    },
    {
      image: "/img/small-lac.jpg",
      title: "Small Laceration",
    },
    {
      image: "/img/flat-mould-black-eyes.jpg",
      title: "Flat Mould Black Eyes",
    },
    {
      image: "/img/infected-test-makeup.jpg",
      title: "Infected Test Makeup",
    },
    {
      image: "/img/who-nose-prosthetic-sculpt.jpg",
      title: "Who Nose Prosthetic Sculpt",
    },
    {
      image: "/img/silicone-a-and-b-burn-scar.jpg",
      title: "Silicone A and B Burn Scar",
    },
    {
      image: "/img/sphinx-cat-pieces.jpg",
      title: "Sphinx Cat Pieces",
    },
    {
      image: "/img/gelatin-toe-keyring.jpg",
      title: "Gelatin Toe Keyring",
    },
    {
      image: "/img/infected-paint-makeup.jpg",
      title: "Infected Paint Makeup",
    },
  ],
};
