import React, { useState } from "react";
import styled from "styled-components";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { entries } from "../../portfolio/entries";
import H2 from "../styled/H2";
import Section from "../styled/Section";
import Main from "../styled/Main";
import Article from "../styled/Article";
import Grid from "../styled/Grid";
import PortfolioImg from "../styled/PortfolioImg";

const SectionPortfolio = styled(Section)`
  color: #2c2c29;
  background: #ffffff;
`;

const Portfolio = () => {
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <SectionPortfolio id="portfolio">
      <Main>
        <Article margin={6}>
          <H2 underlined>Portfolio</H2>
          <Grid>
            {entries.map((entry, i) => (
              <div key={i}>
                <PortfolioImg
                  height={12}
                  src={entry.image}
                  onClick={() => {
                    setImageIndex(i);
                    setLightBoxOpen(true);
                  }}
                />
              </div>
            ))}
          </Grid>
        </Article>
      </Main>
      {lightBoxOpen && (
        <Lightbox
          mainSrc={entries[imageIndex].image}
          nextSrc={entries[(imageIndex + 1) % entries.length].image}
          prevSrc={
            entries[(imageIndex + entries.length - 1) % entries.length].image
          }
          imageTitle={entries[imageIndex].title}
          imageCaption={entries[imageIndex].description}
          enableZoom={false}
          onCloseRequest={() => setLightBoxOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex((imageIndex + entries.length - 1) % entries.length)
          }
          onMoveNextRequest={() =>
            setImageIndex((imageIndex + 1) % entries.length)
          }
        />
      )}
    </SectionPortfolio>
  );
};

export default Portfolio;
