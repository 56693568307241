import React, { useState } from "react";
import styled from "styled-components";
import Button from "./Button";

const Nav = styled.nav`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const NavBarBrand = styled.a`
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavBarLogo = styled.img`
  width: 100px;
  height: auto;
`;

const CollapseButton = styled(Button)`
  padding: 0.25rem 0.75rem;
  font-size: 1.2rem;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Collapsible = styled.div`
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  transition: max-height 0.5s;

  @media (max-width: 768px) {
    text-align: left;
    max-height: ${(props) => (props.collapsed ? "0" : "15rem")};
    overflow: hidden;
  }
`;

const NavBarList = styled.ul`
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 1rem 0;
  }
`;

const NavBar = ({ logo, children }) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Nav>
      {logo && (
        <NavBarBrand href="/">
          <NavBarLogo src={logo} alt="Main logo" />
        </NavBarBrand>
      )}
      <CollapseButton
        type="button"
        onClick={() => setCollapsed((prevState) => !prevState)}
      >
        ≡
      </CollapseButton>
      <Collapsible collapsed={collapsed}>
        <NavBarList onClick={() => setCollapsed(true)}>{children}</NavBarList>
      </Collapsible>
    </Nav>
  );
};

export default NavBar;
