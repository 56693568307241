import styled from "styled-components";
import { sansSerifFont, underlinedStyles } from "./_mixins";

const H2 = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 2rem 0;
  ${sansSerifFont}
  ${(props) => props.underlined && underlinedStyles}
`;

export default H2;
