import React from "react";
import styled from "styled-components";
import catImg from "../../img/cat.png";
import LinkButton from "../styled/LinkButton";
import H1 from "../styled/H1";
import H3 from "../styled/H3";
import Section from "../styled/Section";
import Main from "../styled/Main";
import Article from "../styled/Article";
import Row from "../styled/Row";
import Column from "../styled/Column";

const SectionIntro = styled(Section)`
  color: #ffffff;
  background: no-repeat bottom right url(${catImg}) #111111;
  background-size: contain;
  min-height: 100vh;

  @media (max-width: 768px) {
    background: #111111;
    position: relative;

    &:after {
      content: "";
      background: no-repeat bottom right url(${catImg});
      background-size: 75%;
      opacity: 0.4;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 0;
    }
  }
`;

const Intro = () => (
  <SectionIntro id="home">
    <Main>
      <Article margin={6}>
        <Row margin={0}>
          <Column width={50}>
            <H1>
              Molly
              <br />
              Isaac
            </H1>
            <H3>Makeup Effects and Prosthetic Artist</H3>
            <LinkButton href="#portfolio">View Portfolio</LinkButton>
          </Column>
        </Row>
      </Article>
    </Main>
  </SectionIntro>
);

export default Intro;
