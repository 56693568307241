import styled from "styled-components";
import { sansSerifFont } from "./_mixins";

const H1 = styled.h1`
  font-size: 5rem;
  font-weight: bold;
  margin: 2rem 0;
  ${sansSerifFont}
`;

export default H1;
