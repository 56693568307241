import { css } from "styled-components";

const sansSerifFont = css`
  font-family: "Poppins", sans-serif;
`;

const buttonStyles = css`
  text-decoration: none;
  display: inline-block;
  border: 1px solid #ffffff;
  border-radius: 0.25rem;
  padding: 0.75rem;
  font-size: 0.8rem;
  background-color: #2c2c29;
  color: #ffffff;
  box-shadow: 0px 0px 5px 0px #ffffff;

  &:hover {
    box-shadow: none;
    background-color: #111111;
  }
  ${sansSerifFont}
`;

const underlinedStyles = css`
  position: relative;
  text-align: center;
  padding-bottom: 1.5rem;

  &:before {
    content: "";
    height: 1px;
    background: rgba(100, 100, 100, 0.75);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 250px;
  }

  &:after {
    content: "";
    width: 60px;
    height: 2px;
    background: ${(props) => (props.light ? "#ffffff" : "#2c2c29")};
    position: absolute;
    bottom: 0;
    left: calc(50% - 30px);
  }
`;

export { buttonStyles, sansSerifFont, underlinedStyles };
