import React from "react";
import Navigation from "./components/sections/Navigation";
import Intro from "./components/sections/Intro";
import About from "./components/sections/About";
import Portfolio from "./components/sections/Portfolio";
import Footer from "./components/sections/Footer";

const App = () => {
  return (
    <div className="App">
      <Navigation />
      <Intro />
      <About />
      <Portfolio />
      <Footer/>
    </div>
  );
};

export default App;
